import React from "react"
import SEO from "../components/common/seo"
import Header from "../components/ui/header"
import styled, { createGlobalStyle } from "styled-components"
import vars from "../styles/vars"
import Button from "../components/common/button"

const NotFoundPage = () => (
  <>
    <GlobalStyle />
    <Header />
    <SEO title="404 Not found | Digitisable" />
    <StyledContainer>
      <div className="container pt-4">
        <div className="row">
          <div className="col text-center">
            <img
              src={require("../assets/images/404-not-found.png")}
              alt="404"
              className="img-fluid"
            />
            <h1 className="mb-0 mt-3 font-weight-normal">Page not found</h1>
            <h2 className="mt-3 mb-0">There&#39;s a glitch in the code!</h2>
            <StyledH2 className="mt-0">Our developers are on it.</StyledH2>
            <Button to="/">Back to Homepage</Button>
          </div>
        </div>
      </div>
    </StyledContainer>
  </>
)

export default NotFoundPage

const GlobalStyle = createGlobalStyle`
  body{
    background: ${vars.black};
  }
`
const StyledContainer = styled.section`
  background: ${vars.black};
  color: ${vars.white};
`

const StyledH2 = styled.h2`
  color: ${vars.primaryColour};
`
